export default {
  "formT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire d’inscription"])},
  "formP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificat en Gestion d’officine de pharmacie"])},
  "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
  "prenom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénoms"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
  "lieu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance"])},
  "nationalite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalité"])},
  "residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays de résidence"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
  "diplome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diplôme obtenu le plus élevé"])},
  "intitule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intitulé du diplôme le plus élevé"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décrivez brièvement vos expériences professionnelles pertinentes pour le programme (300 mots maximum)"])},
  "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre ma candidature"])},
  "candidatureT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidature envoyée"])},
  "candidatureP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre candidature a été soumise avec succès. Nous vous contacterons pour confirmer votre pré-sélection."])},
  "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitez notre site web"])},
  "buttonbase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postuler au programme"])},
  "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifiez-vous en Gestion d’officine de pharmacie"])},
  "but1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développez une expertise avérée en gestion d’officine de pharmacie, avec notre programme à distance de certification professionnelle !"])},
  "objectifT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectifs & Compétences visées "])},
  "objectifP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce programme de formation vous permet d’acquérir les compétences essentielles, pour maîtriser les principes fondamentaux de gestion responsable et performante d’une officine de pharmacie. Il met l’accent sur l’ensemble des pôles de management en officine de pharmacie notamment opérationnel, financier, marketing, commercial et les ressources humaines."])},
  "objectifT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion opérationnelle"])},
  "objectifP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maîtriser les fondamentaux de la gestion responsable et performante en officine de pharmacie."])},
  "objectifT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normes éthiques"])},
  "objectifP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprendre les normes éthiques et le cadre légal de la gestion d’officine de pharmacie"])},
  "objectifT3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseil officinal"])},
  "objectifP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maîtriser les principes du conseil officinal et le rôle du pharmacien en santé publique."])},
  "objectifT4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion du médicament"])},
  "objectifP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les risques d’erreurs et le circuit du médicament en pharmacie."])},
  "objectifT5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finances et RH"])},
  "objectifP5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maîtriser la gestion financière et des ressources humaines en officine de pharmacie."])},
  "objectifT6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing et ventes"])},
  "objectifP6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développer une stratégie marketing et commerciale efficace en officine de pharmacie."])},
  "publicsT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publics cibles & pré-requis"])},
  "publicsT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionnels de la santé"])},
  "publicsP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pharmaciens titulaires ; pharmaciens assistants; étudiants en pharmacie."])},
  "publicsT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres publics cibles"])},
  "publicsP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce programme est exclusivement réservé aux personnes ayant un parcours de formation universitaire en sciences pharmaceutiques."])},
  "moyensT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyens pédagogiques"])},
  "moyensP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce programme est basé sur une méthode pédagogique d’approche par compétences et s’insère parfaitement dans votre agenda sans bousculer votre vie professionnelle ou personnelle."])},
  "formatT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
  "formatP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cours sont dispensés à temps partiel, exclusivement à distance, en mode synchrone (classes virtuelles) et asynchrone (cours pré-enregistrés)."])},
  "comiteT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comité"])},
  "comiteP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le comité pédagogique de ce programme est composé d’experts internationaux reconnus en management d’officine de pharmacie."])},
  "dureeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
  "dureeP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40 heures de cours."])},
  "periodeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
  "periodeP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une nouvelle cohorte est lancée à chaque début de mois."])},
  "evaluationT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
  "evaluationP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la fin du programme, l’apprenant(e) est soumis(e) à une épreuve de contrôle de connaissance en ligne au format QCM."])},
  "certificationT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certification"])},
  "certificationP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le programme est sanctionné par un certificat international professionnel\naccrédité en « gestion d’officine de pharmacie », qui est délivré aux apprenant(e)s ayant\nobtenu une note supérieure ou égale à 60% au terme du contrôle des connaissances."])},
  "contenuT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu du Programme"])},
  "droitInscriptionT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droit d’inscription"])},
  "droitInscriptionTN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Possibilité de paiements par tranches*"])},
  "droitInscriptionP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les droits d’inscription à la formation s’élèvent à"])},
  "droitInscriptionP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’inscription n’est considérée comme définitive qu’après règlement de ce montant auprès de\nl’organisme de formation médicale continue REMA, à condition que votre candidature soit acceptée. Places limitées !"])},
  "placeDisponible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les places disponibles étant limitées pour ce programme de certification, seules les candidatures\npertinentes seront retenues. Les candidats sélectionnés seront contactés par nos équipes afin de\nfinaliser leur inscription. Merci."])},
  "produitT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])},
  "renseignements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renseignements"])},
  "entrepriseT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
  "entrepriseP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
  "entrepriseP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
  "entrepriseP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrière"])},
  "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2022 REMA. Tous droits réservés."])},
  "confidentialites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentialités"])},
  "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d’utilisation"])}
}